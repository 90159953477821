<script setup>
import { watch, ref, computed } from "vue";
import useEventsBus from "../services/EventBusService";
import { EMIT_FONTSIZE } from "../services/EventBusConstants";
import { storeToRefs } from "pinia";
import { useUserSettingsStore } from "../stores/userSettingsStore";

const props = defineProps({
  texttodisplay: { required: true },
  lang: {
    type: Object,
    required: true,
  },
  cssClass: {
    type: String,
    required: false,
  },
  limit: {
    type: Number,
    default: 9999999999, // high default value, so all text will be displayed unless smaller limit is specified
  },
});

// ----------------------------------------
// 1) FONT SIZES
// ----------------------------------------
const userSettingsStore = useUserSettingsStore();
const { baseFontSize } = storeToRefs(userSettingsStore);

const textSizeUnit = "rem";
const baseSize = ref(baseFontSize);

const germanBase = 0.1;
const arabicBase = 0.1;
const copticBase = -0.01;

const titleAdd = 0.1;
const commentAdd = -0.05;
const introAdd = -0.2;

// Font Size`s - Text

const sizeGerman = computed(() => {
  return parseFloat(baseSize.value) + germanBase + textSizeUnit;
});
const sizeArabic = computed(() => {
  return parseFloat(baseSize.value) + arabicBase + textSizeUnit;
});
const sizeCoptic = computed(() => {
  return parseFloat(baseSize.value) + copticBase + textSizeUnit;
});

// Font Size`s - Title
const sizeGermanTitle = computed(() => {
  return parseFloat(sizeGerman.value) + titleAdd + textSizeUnit;
});

const sizeArabicTitle = computed(() => {
  return parseFloat(sizeArabic.value) + titleAdd + textSizeUnit;
});

const sizeCopticTitle = computed(() => {
  return parseFloat(sizeCoptic.value) + titleAdd + textSizeUnit;
});

// Font Size`s - Comments

const sizeNonCopticComment = computed(() => {
  return baseSize.value + commentAdd + textSizeUnit;
});
const sizeNonCopticIntro = computed(() => {
  return baseSize.value + introAdd + textSizeUnit;
});

// Receive emit
const { bus } = useEventsBus();
watch(
  () => bus.value.get(EMIT_FONTSIZE),
  (val) => {
    const [EMIT_FONTSIZE] = val ?? [];
    baseSize.value = EMIT_FONTSIZE;
  }
);

// ----------------------------------------
// 2) TEXT & "READ MORE" LOGIC
// ----------------------------------------

// Convert texttodisplay into an array if it isn't already
let textArray = [];
if (Array.isArray(props.texttodisplay)) {
  textArray = props.texttodisplay;
} else {
  textArray = [props.texttodisplay];
}

// Join array into a single string (to handle the entire block)
const fullText = computed(() => textArray.join(" "));

// Toggle state for read-more
const showMore = ref(false);

// Derive truncated text
const truncatedText = computed(() => {
  if (fullText.value.length <= props.limit) {
    return fullText.value;
  } else {
    return fullText.value.substring(0, props.limit);
  }
});

// Toggle function
function toggleShowMore() {
  showMore.value = !showMore.value;
}
</script>
<template>
  <div
    :dir="lang.direction"
    :class="cssClass ? cssClass : lang.cssText"
    :lang="lang.iso631_1"
    :xml:lang="lang.iso631_1"
  >
    <!-- If text is longer than limit and we haven't expanded, show truncated text + show-more link -->
    <template v-if="!showMore && fullText.length > limit">
      <!-- Display truncated text -->
      <span v-html="truncatedText + '...'"></span>
      <!-- 'More' button -->
      <span @click="toggleShowMore" style="text-decoration: underline; cursor: pointer">
        {{ $t("text.more") }}
      </span>
    </template>

    <!-- If we have expanded (showMore = true) OR text is short enough, show the full text -->
    <template v-else>
      <!-- show the joined full text -->
      <span v-html="fullText"></span>

      <!-- 'Less' button only if content exceeds limit -->
      <span v-if="fullText.length > limit" @click="toggleShowMore" style="text-decoration: underline; cursor: pointer">
        {{ $t("text.less") }}
      </span>
    </template>
  </div>
</template>
<style>
.germanText {
  font-family: "Roboto", "Noto Sans", Arial, sans-serif;
  line-height: 1.5;
  font-weight: normal;
  font-size: v-bind("sizeGerman");
  text-align: justify;
  text-justify: inter-word;
  hyphens: auto;
  -webkit-hyphens: auto;
  text-justify: inter-word;
  word-break: break-word;
}
.arabicText {
  font-family: "Noto Naskh Arabic", "Arial", sans-serif;
  line-height: 1.7;
  font-weight: normal;
  font-size: v-bind("sizeArabic");
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.copticText1 {
  font-family: FreeSerifAthanasius !important;
  line-height: 1.65;
  font-weight: normal;
  font-size: v-bind("sizeCoptic");
  text-align: justify;
  text-justify: inter-word;
  hyphens: none;
  word-break: break-word;
}

.copticTitle1 {
  font-family: FreeSerifAthanasius !important;
  line-height: 1.5;
  font-weight: normal;
  font-size: v-bind("sizeCopticTitle");
}

.germanTitle {
  line-height: 1.3;
  font-weight: bold;
  font-size: v-bind("sizeGermanTitle");
  tab-size: 20;
  white-space: pre;
  white-space: normal;
  hyphens: auto;
}

.arabicTitle {
  line-height: 1.8;
  font-weight: bold;
  font-size: v-bind("sizeArabicTitle");
}

.comment {
  font-size: v-bind("sizeNonCopticComment");
}

/* for intro/extro of readings */
.intro {
  font-family: Georgia;
  font-size: v-bind("sizeNonCopticIntro");
  margin-left: 0px;
  font-style: italic;
}
</style>
