<script setup>
import { IonCol, IonRow } from "@ionic/vue";
import { useUserSettingsStore } from "../stores/userSettingsStore";
import { storeToRefs } from "pinia";
import { configs } from "../utils/ConfigUtil";
import { langContainsText } from "../utils/GeneralUtils";
import VarusText from "../components/VarusText.vue";
const userStore = useUserSettingsStore();
const { userTextLanguages } = storeToRefs(userStore);

defineProps({ role: String, paragraph: Object });
</script>

<template>
  <ion-row class="roleRow">
    <template v-for="lang in userTextLanguages" :key="lang.key">
      <ion-col v-if="typeof paragraph.text != 'undefined' && langContainsText(paragraph.text, lang.key)">
        <VarusText :texttodisplay="configs.roles[role][lang.key]" :lang="lang" />
      </ion-col>
    </template>
  </ion-row>
</template>

<style>
.roleRow {
  margin-top: 10px;
  margin-bottom: -10px;
  color: var(--ion-text-color3);
  font-style: italic;
}
</style>
