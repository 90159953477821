import { ref } from "vue";
import { DebugLevel, appLog } from "../functions/Logging";
import { getPrayer, getPsalm } from "../services/PrayerImportService";
import { getReadingAsPrayer } from "../services/ReadingService";
import staticReadings from "../data/katameros/static_readings.json";
import { ensureArray } from "../utils/GeneralUtils";
import { isDateWithinRange } from "../utils/CopticDateUtils";

/**
 *
 * The functions in this file are composable functions (encapsulate and reuse stateful logic).
 * They are passed a reactive state (e.g. church season) and the composable creates watchers that retrieve the season-dependent prayers using the passed state.
 *
 */

const FILE_NAME = "[PrayerFilterService]";

// filter-prayer
export function useGetFilteredPrayers(book, seasonContext, reading) {
  const filteredPrayers = ref([]);

  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "seasonContext", seasonContext);

  // Helper function to process individual prayers, including nested insertPrayer types
  const processPrayer = (prayer, prayersAccumulator) => {
    // 1. Skip if this prayer is disabled.
    if (prayer.disabled) return;

    // 2. Skip if this prayer is filtered out by season rules.
    const needsFiltering = book.prayersToBeFiltered;
    if (needsFiltering && !applyDisplayRules(prayer, seasonContext)) return;

    let importedContent;

    switch (prayer.type) {
      case "insertPsalm": {
        importedContent = getPsalm(prayer);
        if (importedContent) {
          prayersAccumulator.push(importedContent);
        }
        break;
      }

      case "insertPrayer": {
        importedContent = getPrayer(prayer);

        // Apply season rules on the newly imported prayer(s).
        let shouldDisplay = true;
        if (needsFiltering && !applyDisplayRules(importedContent, seasonContext)) {
          shouldDisplay = false;
        }

        // Exclude silent prayers (TODO: exclude/include silentPrayers based on user settings-value).
        if (importedContent?.[0]?.silent) {
          shouldDisplay = false;
        }

        // If the importedContent is an array, process each nested prayer recursively
        if (importedContent && shouldDisplay) {
          if (Array.isArray(importedContent)) {
            importedContent.forEach((nestedPrayer) => {
              processPrayer(nestedPrayer, prayersAccumulator);
            });
          } else {
            processPrayer(importedContent, prayersAccumulator);
          }
        }
        break;
      }

      case "insertReading": {
        const { service, key: rType } = prayer;
        const readingOfService = reading?.liturgyServices?.[service]?.[rType];

        if (!readingOfService) {
          appLog(DebugLevel.WARN, FILE_NAME, `Missing reading for service: ${service}, type: ${rType}`);
          break;
        }

        const readingAsPrayer = getReadingAsPrayer(rType, readingOfService);
        if (readingAsPrayer) {
          prayersAccumulator.push(...readingAsPrayer);
        }
        break;
      }

      case "insertStaticReading": {
        const { service, key: rType } = prayer;
        const readingOfService = staticReadings?.[service]?.[rType];
        const readingAsPrayer = getReadingAsPrayer(rType, readingOfService);
        if (readingAsPrayer) {
          prayersAccumulator.push(...readingAsPrayer);
        }
        break;
      }

      default: {
        // If the prayer doesn't match any special type, push it as-is.
        prayersAccumulator.push(prayer);
        break;
      }
    }

    // Filter paragraphs if present.
    if (prayer.paragraphs) {
      prayer.paragraphs = getFilteredParagraphs(prayer.paragraphs, seasonContext);
    }
  };

  // Reactive computation of filtered prayers
  const filterPrayers = (book, seasonContext, reading) => {
    if (!book || !book.prayers) {
      return [];
    }

    const prayers = [];

    book.prayers.forEach((prayer) => {
      processPrayer(prayer, prayers);
    });

    return prayers;
  };

  filteredPrayers.value = filterPrayers(book, seasonContext, reading);

  return { filteredPrayers };
}

function getFilteredParagraphs(paragraphs, seasonContext) {
  let filteredParagraphs = [];

  paragraphs.forEach((paragraph) => {
    // skip disabled paragraphs
    if (paragraph.disabled) {
      return;
    }

    const tempParagraph = applyDisplayRules(paragraph, seasonContext);
    if (tempParagraph != null) {
      filteredParagraphs.push(tempParagraph);
    }
  });
  return filteredParagraphs;
}

/**
 * Determines whether a given set of conditions (condition) is satisfied based on the current context (context).
 * Returns true or false, indicating whether ALL specified conditions are met.
 * @param {Object} condition
 * @param {Object} context
 * @returns {boolean} If all these conditions are met, the function returns true; otherwise, it returns false.
 */
const evaluateCondition = (condition, context) => {
  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Evaluating Condition:", condition);

  const { season_is, dayOfWeek, is_fasting_day, isLordFeast, season_type, bishop_present, date_range, daysToEastern } =
    condition;

  // Normalize context values
  const currentSeasonKey = context?.season?.key?.toLowerCase();
  const currentSeasonType = context?.season?.season_type?.toLowerCase();
  const currentDayOfWeek = context?.dayOfWeek;
  const currentIsLordFeast = context?.isLordFeast;
  const currentIsFastingDay = context?.isFastingDay;
  const currentCopticDate = context?.copticDate;
  const currentDaysToEastern = context?.daysToEastern;
  let conditionMet = true;

  // Ensure rule properties are arrays
  const normalizedSeasonIs = ensureArray(season_is).map((s) => s.toLowerCase());
  const normalizedSeasonType = ensureArray(season_type).map((s) => s.toLowerCase());
  const normalizedDayOfWeek = ensureArray(dayOfWeek);

  // Evaluate season_is
  if (normalizedSeasonIs.length > 0) {
    conditionMet = conditionMet && normalizedSeasonIs.includes(currentSeasonKey);
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `season_is: ${conditionMet} (Expected: ${normalizedSeasonIs}, Actual: ${currentSeasonKey})`
    );
  }

  // Evaluate season_type
  if (normalizedSeasonType.length > 0) {
    conditionMet = conditionMet && normalizedSeasonType.includes(currentSeasonType);
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `season_type: ${conditionMet} (Expected: ${normalizedSeasonType}, Actual: ${currentSeasonType})`
    );
  }

  // Evaluate dayOfWeek
  if (normalizedDayOfWeek.length > 0) {
    conditionMet = conditionMet && normalizedDayOfWeek.includes(currentDayOfWeek);
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `dayOfWeek: ${conditionMet} (Expected: ${normalizedDayOfWeek}, Actual: ${currentDayOfWeek})`
    );
  }

  // Evaluate isLordFeast
  if (isLordFeast !== undefined) {
    conditionMet = conditionMet && isLordFeast === currentIsLordFeast;
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `isLordFeast: ${conditionMet} (Expected: ${isLordFeast}, Actual: ${currentIsLordFeast})`
    );
  }

  // Evaluate is_fasting_day
  if (is_fasting_day !== undefined) {
    conditionMet = conditionMet && is_fasting_day === currentIsFastingDay;
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `is_fasting_day: ${conditionMet} (Expected: ${is_fasting_day}, Actual: ${currentIsFastingDay})`
    );
  }

  // Evaluate bishop_present
  if (bishop_present !== undefined) {
    const bishopStatus = false; // TODO: Implement actual logic to determine bishop presence
    conditionMet = conditionMet && bishop_present === bishopStatus;
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `bishop_present: ${conditionMet} (Expected: ${bishop_present}, Actual: ${bishopStatus})`
    );
  }

  // Evaluate date_range
  if (date_range) {
    const { start, end } = date_range;
    if (!start || !end) {
      appLog(DebugLevel.WARN, FILE_NAME, "date_range must have both 'start' and 'end'.");
      conditionMet = false;
    } else {
      const [startMonth, startDay] = start.split("-").map(Number);
      const [endMonth, endDay] = end.split("-").map(Number);

      // Validate dates
      if (
        isNaN(startMonth) ||
        isNaN(startDay) ||
        isNaN(endMonth) ||
        isNaN(endDay) ||
        startMonth < 1 ||
        startMonth > 13 ||
        endMonth < 1 ||
        endMonth > 13 ||
        startDay < 1 ||
        startDay > 30 || // Assuming Coptic months have max 30 days
        endDay < 1 ||
        endDay > 30
      ) {
        appLog(DebugLevel.WARN, FILE_NAME, "Invalid date_range format or values.");
        conditionMet = false;
      } else {
        // Compare dates
        const current = { month: parseInt(currentCopticDate.month, 10), day: parseInt(currentCopticDate.day, 10) };
        const startDate = { month: startMonth, day: startDay };
        const endDate = { month: endMonth, day: endDay };

        const isWithinRange = isDateWithinRange(current, startDate, endDate);
        conditionMet = conditionMet && isWithinRange;
      }
    }
  }

  // Evaluate daysToEastern
  if (daysToEastern) {
    const { start, end } = daysToEastern;
    const min = Math.min(start, end);
    const max = Math.max(start, end);
    conditionMet = currentDaysToEastern >= min && currentDaysToEastern <= max;
    appLog(
      DebugLevel.PRAYER_FILTER,
      FILE_NAME,
      `daysToEastern: ${conditionMet} (Days Until Easter: ${currentDaysToEastern}, Expected Range: ${start} to ${end})`
    );
  }

  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Condition Met:", conditionMet);
  return conditionMet;
};

/**
 * Determines whether ANY of the provided rule groups are satisfied based on the current context.
 *
 * @param {Array|Object} rulesArray
 * @param {Object} context
 * @returns {boolean} true if at least one group of conditions is met; otherwise, it returns false.
 */
const evaluateRules = (rulesArray, context) => {
  if (!Array.isArray(rulesArray)) {
    rulesArray = [rulesArray];
  }

  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Evaluating Rules Array:", rulesArray);

  const result = rulesArray.some((group, groupIndex) => {
    if (Array.isArray(group)) {
      appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, `Evaluating Group ${groupIndex + 1}:`, group);
      const groupResult = group.every((condition, conditionIndex) => {
        const conditionResult = evaluateCondition(condition, context);
        appLog(
          DebugLevel.PRAYER_FILTER,
          FILE_NAME,
          `Group ${groupIndex + 1}, Condition ${conditionIndex + 1} Result:`,
          conditionResult
        );
        return conditionResult;
      });
      appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, `Group ${groupIndex + 1} Result:`, groupResult);
      return groupResult;
    } else {
      const conditionResult = evaluateCondition(group, context);
      appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, `Single Condition Group Result:`, conditionResult);
      return conditionResult;
    }
  });

  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "evaluateRules Final Result:", result);
  return result;
};

/**
 * Determines whether to display a prayer based on given rules and context
 *
 * @param {Object} context
 * @param {Object} rules
 * @returns {boolean}
 */
export const shouldDisplayElement = (context, rules) => {
  const { displayWhen, displayNot } = rules;

  // If both displayWhen and displayNot are undefined or empty, return true
  if (
    (!Array.isArray(displayWhen) || displayWhen.length === 0) &&
    (!Array.isArray(displayNot) || displayNot.length === 0)
  ) {
    appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "No applicable rules. Displaying element.");
    return true;
  }

  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Evaluating displayNot rules:", displayNot);

  // If displayNot rules are present and satisfied, exclude the prayer
  if (displayNot && evaluateRules(displayNot, context)) {
    appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "displayNot rules satisfied. Excluding element.");
    return false;
  }

  // If displayWhen rules are present, include the prayer only if they are satisfied
  if (displayWhen) {
    appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Evaluating displayWhen rules:", displayWhen);

    if (evaluateRules(displayWhen, context)) {
      appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "displayWhen rules satisfied. Including prayerOrParagraph.");
      return true;
    } else {
      appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "displayWhen rules not satisfied. Excluding prayerOrParagraph.");
      return false;
    }
  }

  // If displayWhen is not present and displayNot did not exclude, include by default
  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "No displayWhen rules. Including prayerOrParagraph by default.");
  return true;
};

/**
 * Applies season rules to a prayer or paragraph
 *
 * @param {Object} element2check
 * @param {Object} seasonContext
 * @returns {Object|null} Returns the object if it should be displayed, else null
 */
function applyDisplayRules(element2check, seasonContext) {
  const { displayWhen, displayNot } = element2check;

  // Log the current prayerOrParagraph being processed
  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Processing prayerOrParagraph:", element2check);

  // If both displayWhen and displayNot are undefined or empty, return the object
  if (
    (!Array.isArray(displayWhen) || displayWhen.length === 0) &&
    (!Array.isArray(displayNot) || displayNot.length === 0)
  ) {
    appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "No applicable rules. Displaying prayerOrParagraph.");
    return element2check;
  }

  const rules = { displayWhen, displayNot };
  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Applied Rules:", rules);

  const display = shouldDisplayElement(seasonContext, rules);
  appLog(DebugLevel.PRAYER_FILTER, FILE_NAME, "Display Prayer:", element2check, "Result:", display);

  return display ? element2check : null; // Return the object if it should be displayed, else null
}
