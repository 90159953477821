import { DebugLevel, appLog } from "../functions/Logging";
import { useBookStore } from "../stores/bookStore";
import { generateString, readFileFromDisk, attachPropertyToObject, toArray } from "../utils/GeneralUtils";

const DATA_FILE_EXT = ".json";
const FILE_NAME = "[PrayerImportService]";

/**
 *
 * extract and prayer.key and prayer.titleDisplay from the given prayer
 *
 * @param {} prayers a prayer or a list of prayer can be provided
 * @returns list of prayer.key and prayer.titleDisplay
 */
function getPrayerTitles(prayers) {
  let prayerTitles = [];
  if (Array.isArray(prayers)) {
    Object.entries(prayers).forEach((prayer) => {
      let tempTitle = getPrayerTitlesImpl(prayer);
      if (tempTitle != undefined) {
        prayerTitles.push(tempTitle);
      }
    });
  } else {
    let tempTitle = getPrayerTitlesImpl(prayers);
    if (tempTitle != undefined) {
      return tempTitle;
    }
  }
}

/**
 * extract and prayer.key and prayer.titleDisplay from the given prayer
 *
 * @param {} chapters
 * @returns
 */
function getPrayerTitlesImpl(prayer) {
  // check for undefined
  appLog(DebugLevel.TRACE, FILE_NAME, "getPrayerTitles for prayer", prayer);
  if (typeof prayer == "undefined") {
    appLog(DebugLevel.WARN, FILE_NAME, "getPrayerTitles > prayer is undefined");
    return;
  }
  if (prayer.titleDisplay != undefined && prayer.key != undefined) {
    return {
      key: prayer.key,
      titleDisplay: prayer.titleDisplay,
    };
  }
}

/**
 * return parent of the given chapter
 *
 * @param {} chapters
 * @returns
 */
function getParents(chapters) {
  let parents = { parents: [], type: "" };
  try {
    let tempParent = useBookStore().metaData.get(chapters.parent);
    parents.parents = tempParent.books;
    parents.type = tempParent.type;
  } catch (error) {
    appLog(DebugLevel.ERROR, FILE_NAME, "Error reading Parents from Chapters from following object: ", error.message);
    appLog(DebugLevel.ERROR, FILE_NAME, chapters);
  }
  return parents;
}

/**
 * returns the prayers of the given chapters
 *
 * @param {} chapters
 * @returns
 */
function getPrayerFromChapters(chapters) {
  let prayers;
  try {
    prayers = chapters.prayers;
  } catch (error) {
    appLog(DebugLevel.ERROR, FILE_NAME, `Error reading Prayers Chapters ${chapters}: ` + error.message);
  }
  return prayers;
}

export function makeKeyOfPrayersUnique(prayers) {
  if (prayers == undefined || prayers.length == 0) {
    return [];
  }
  let newPrayers = [];
  prayers.forEach((prayer) => {
    if (prayer == undefined) return;
    newPrayers.push(makeKeyOfPrayerUnique(prayer));
  });
  return newPrayers;
}

export function makeKeyOfPrayerUnique(prayer) {
  if (prayer == undefined) {
    return {};
  }
  if (prayer.key == undefined) {
    prayer.key = generateString();
  } else {
    prayer.key = prayer.key + "_" + generateString();
  }
  return prayer;
}

function handleAdd(objectsToAdd, prayer) {
  appLog(DebugLevel.TRACE, FILE_NAME, "handleAdd - Object has add-Tag: ", prayer);
  if (Array.isArray(prayer)) {
    Object.entries(prayer).forEach(([index]) => {
      prayer[index] = attachPropertyToObject(objectsToAdd, prayer[index]);
    });
  } else {
    prayer = attachPropertyToObject(objectsToAdd, prayer);
  }
  return prayer;
}

/**
 *
 * @param {*} paragraphs array of paragraphs to add
 * @param {*} newprayer  target prayer to which paragraphs to should be added
 * @param {*} position  0 = add to start of existing paragraph list,  1 = add to end
 * @returns
 */
function handleAddParagraphs(paragraphs, prayer, position) {
  appLog(DebugLevel.TRACE, FILE_NAME, "handleAddParagraphs", "Prayer has addParagraphs-Tag");
  paragraphs.forEach((element) => {
    let parag = getParagraph(element);
    if (typeof parag == "undefined") {
      return prayer;
    }
    if (position == 0) {
      prayer.paragraphs.unshift(...parag);
    } else {
      prayer.paragraphs.push(...parag);
    }
  });
  return prayer;
}

/**
 *
 * @param {*} prayer
 * @param {*} importedPrayer
 * @returns
 */
function checkForAdditionalProperties(prayer, importedPrayer) {
  // check for add-Objects
  if (typeof prayer.add !== "undefined") {
    importedPrayer = handleAdd(prayer.add, importedPrayer);
  }

  // check for addParagraphs to Start
  let pStart = prayer.addParagraphsToStart;
  if (pStart != undefined && Array.isArray(pStart) && pStart.length > 0) {
    importedPrayer = handleAddParagraphs(pStart, importedPrayer, 0);
  }

  // addParagraphs to End
  let pEnd = prayer.addParagraphsToEnd;
  if (pEnd != undefined && Array.isArray(pEnd) && pEnd.length > 0) {
    importedPrayer = handleAddParagraphs(pEnd, importedPrayer, 1);
  }
  return importedPrayer;
}

/**
 *
 * @param {*} pgraph
 * @returns
 */
const getParagraph = function (pgraph) {
  // check for undefined
  if (typeof pgraph == "undefined") {
    return undefined;
  }

  let paragraphsToAdd = [];
  if (typeof pgraph.folder != "undefined" && typeof pgraph.paragraph != "undefined") {
    // get file
    let pars = readFileFromDisk(pgraph.paragraph + DATA_FILE_EXT, pgraph.folder + "/paragraphs");

    // add role to first paragraph
    if (typeof pgraph.role != "undefined") {
      pars[0]["role"] = pgraph.role;
    }
    paragraphsToAdd.push(...pars);
  }

  // check for add-Objects
  if (pgraph.type == "text" || pgraph.type == "comment") {
    paragraphsToAdd.push(...toArray(pgraph));
  }

  return paragraphsToAdd;
};

/**
 *
 * @param {*} prayer
 * @returns
 */
const getPrayer = function (prayer) {
  // check for undefined
  if (typeof prayer == "undefined") {
    appLog(DebugLevel.WARN, FILE_NAME, "getPrayer > prayer is undefined prayer");
    return undefined;
  }
  appLog(DebugLevel.TRACE, FILE_NAME, `getPrayer prayer`, prayer);
  if (typeof prayer.prayerKey == "undefined" || typeof prayer.folder == "undefined") {
    appLog(
      DebugLevel.WARN,
      FILE_NAME,
      `getPrayer > undefined prayer.prayerKey = ${prayer.prayerKey}) | prayer.folder = ${prayer.folder}`
    );
    return undefined;
  }

  // get file
  let prayersFromFile = readFileFromDisk(prayer.prayerKey + DATA_FILE_EXT, prayer.folder + "/prayers");
  if (typeof prayersFromFile == "undefined") {
    appLog(DebugLevel.WARN, FILE_NAME, `getPrayer imported undefined file for prayer ${prayer.prayerKey}`);
    return undefined;
  }

  // check if Array and for additional properties
  if (Array.isArray(prayersFromFile)) {
    Object.entries(prayersFromFile).forEach(([index]) => {
      prayersFromFile[index] = checkForAdditionalProperties(prayer, prayersFromFile[index]);
      if (prayersFromFile[index].repeatableInPrayerList == true) {
        prayersFromFile[index] = makeKeyOfPrayerUnique(prayersFromFile[index]);
      }
    });
  } else {
    prayersFromFile = checkForAdditionalProperties(prayer, prayersFromFile);
    // make an array
    prayersFromFile = toArray(prayersFromFile);
    if (prayersFromFile[0].repeatableInPrayerList == true) {
      prayersFromFile[0] = makeKeyOfPrayerUnique(prayersFromFile[0]);
    }
  }

  appLog(DebugLevel.TRACE, FILE_NAME, `imported file for ${prayer.prayerKey}`, prayersFromFile);
  return prayersFromFile;
};

/**
 *
 * @param {*} psalm
 * @returns
 */
const getPsalm = function (psalm) {
  // check for undefined
  if (typeof psalm == "undefined" || typeof psalm.psalm == "undefined") {
    appLog(DebugLevel.WARN, FILE_NAME, `getPsalm > psalm is undefined or psalm.psalm`);
    return undefined;
  }

  // get file
  let psalmFromFile = readFileFromDisk(psalm.psalm + DATA_FILE_EXT, "agpeyapsalmen");
  if (typeof psalmFromFile == "undefined") {
    appLog(DebugLevel.WARN, FILE_NAME, `getPsalm imported undefined file for psalm ${psalm.psalm}`);
    return undefined;
  }

  // check if Array
  if (!Array.isArray(psalmFromFile)) {
    // make an array
    psalmFromFile = toArray(psalmFromFile);
  }

  appLog(DebugLevel.TRACE, FILE_NAME, `imported file for ${psalm.psalm}`, psalmFromFile);
  return psalmFromFile[0];
};

export { getParagraph, getPrayer, getPsalm, getPrayerFromChapters, getParents, getPrayerTitles };
