// Import Basics
import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import { Bugfender } from "@bugfender/sdk";

// Import Components
import App from "./App.vue";
import BaseLayout from "./components/base/BaseLayout.vue";

// Import Plugins & Tools
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";

// Book Store
import { useBookStore } from "./stores/bookStore";

// Calendar Store
import { useCalendarStore } from "./stores/calendarStore";

// ** CSS **
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Ionic Dark Mode */
import "@ionic/vue/css/palettes/dark.class.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";

// i18n
const messages = {
  deu: JSON.parse(JSON.stringify(require(`./locales/deu.json`))),
  ara: JSON.parse(JSON.stringify(require(`./locales/ara.json`))),
  eng: JSON.parse(JSON.stringify(require(`./locales/eng.json`))),
};
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "deu",
  messages,
});
const app = createApp(App).use(i18n).use(IonicVue).use(router).use(createPinia());

app.component("base-layout", BaseLayout);
app.provide(/* key */ "message", /* value */ "Message provided by main.js and injected here");

router.isReady().then(() => {
  app.mount("#app");
});

// Global variables
app.config.globalProperties.$nodeMode = process.env.NODE_ENV;
app.config.globalProperties.$appVersion = process.env.VUE_APP_VERSION;

if (process.env.NODE_ENV == "production") {
  // Bugfender Initialize
  Bugfender.init({
    appKey: "E81xybxn6J94ohJDHRlxs6JlGWVbuufT",
    logBrowserEvents: process.env.NODE_ENV !== "production",
    logUIEvents: false,
    build: process.env.VUE_APP_VERSION,
  });

  // Bugfender - catch unhandled errors and report them to Bugfender - custom Vue errorHandler
  app.config.errorHandler = (err, _, info) => {
    if (err instanceof Error) {
      Bugfender.sendCrash(
        `${err.name}: ${err.message ?? "N/A"}`,
        `Vue error info: ${info}\nStack: ${err.stack ?? "N/A"}`
      );
    } else {
      Bugfender.sendCrash(String(err), `Vue error info: ${info}`);
    }
  };
}

// Building metadata cache
const bookStore = useBookStore();
await bookStore.buildMetaDataCache();
await bookStore.setRootBooks();

// set Calendar
const calStore = useCalendarStore();
calStore.initializeCalendarStore(i18n.global.locale.value);

// special dates for testing purposes:
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 8, 11)); // Neyrouz;
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 0, 7));  // Nativity
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 0, 19)); // Theophany
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2024, 8, 27)); // Kreuzfest;
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 1, 24)); // Lent  - Montag
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 1, 30)); // Lent - Sonntag
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 3, 19)); // Bright Saturday (Lichtsamstag)
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 6, 6));  // 29. (So)
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 3, 20)); // Auferstehhung
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 3, 23)); // Pentecost Period
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 4, 29)); // Himmelfahrt
// calStore.initializeCalendarStore(i18n.global.locale.value, new Date(2025, 5, 8));  // Pfingsten
